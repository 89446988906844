/* 23.1 signup greetings */
.signup {
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 10px 35px 0px rgba(0, 0, 0, 0.1);
    &-greeting {
        background-position: top center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 70px 0 140px;
        text-align: center;
        @include desktop-lg {
            padding-bottom: 80px;
        }
        @include tablet {
            padding-top: 200px;
        }
        @include mobile {
            padding-top: 400px;
        }
        img {
            margin-bottom: 50px;
            position: relative;
            z-index: 9;
        }
        h4 {
            color: $white;
            margin-bottom: 20px;
            position: relative;
            z-index: 9;
        }
        p {
            color: $white;
            position: relative;
            z-index: 9;
            margin: 0 40px;
        }
    }
    /* 23.2 signup form */
    &-form {
        padding: 50px 50px 50px 35px;
        @include desktop-lg {
            padding: 50px 15px 50px 0;
        }
        @include tablet {
            padding: 50px;
        }
        @include mobile {
            text-align: center;
        }
        h4 {
            float: left;
            font-size: 26px;
            margin-bottom: 35px;
            @include mobile {
                float: unset;
            }
        }
        a {
            color: $primary-color;
        }
        .btn {
            margin-top: 30px;
        }
        p.signup-with {
            display: inline-block;
            margin-top: 42px;
        }
        ul {
            margin-top: 37px;
            display: inline-block;
            li {
                a {
                    height: 30px;
                    width: 30px;
                    line-height: 30px;
                    color: $white;
                    text-align: center;
                    border-radius: 50%;
                    display: inline-block;
                }
            }
        }
    }
}