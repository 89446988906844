/* 1.1 typography */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Poppins:300,400,500,600,700|Work+Sans:400,500,600');

body {
  font-family: $primary-font;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
}

p,.paragraph {
  font-weight: 400;
  color: $text-color;
  font-size: 14px;
  line-height: 25px;
  font-family: $secondary-font;
}

h1,h2,h3,h4,h5,h6 {
  color: $text-color-dark;
  font-weight: 500;
  font-family: $primary-font;
}

h1, .h1{
  font-size: 60px;
  @include mobile-xs {
      font-size: 30px;
  }
}

h2, .h2{
  font-size: 36px;
}

h3, .h3{
  font-size: 30px;
}

h4, .h4{
  font-size: 20px;
}

h5, .h5{
  font-size: 18px;
}

h6, .h6{
  font-size: 14px;
}
