/* 1.3 Button style */
.btn{
    font-size: 14px;
    font-family: $secondary-font;
    text-transform: capitalize;
    padding: 16px 44px;
    border-radius: 35px;
    font-weight: 600;
    border: 0;
    position: relative;
    z-index: 1;
    transition: .2s ease;
    &:focus{
        outline: 0;
        box-shadow: none !important;
    }
    &:active{
        box-shadow: none;
    }
}

.btn-primary{
    background: $primary-color;
    color: $white;
    &:active{
        background: $primary-color !important;
    }
    &:hover{
        background: lighten($color: $primary-color, $amount: 10);
    }
}

.btn-secondary{
    background: $secondary-color;
    color: $white;
    &:active{
        background: $primary-color !important;
    }
    &:hover{
        background: $primary-color !important;
        background: lighten($color: $primary-color, $amount: 10);
    }
}

.btn-light{
    background: $white;
    color: $text-color-dark;
    border: 2px solid $white;
    &:hover{
        background: transparent;
        border: 2px solid $white;
        color: $white;
    }
}

.btn-outline{
    background: transparent;
    color: $text-color-dark;
    border: 2px solid $secondary-color;
    &:hover{
        background: $secondary-color;
        color: $white;
    }
}

.btn-sm{
    padding: 13px 40px !important;
}