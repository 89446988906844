/* 21.4.3 pagination */
.pagination {
    .page-item {
        .page-link {
            background: transparent;
            border: none;
            color: $black;
            font-family: $secondary-font;
            font-weight: 600;
            transition: .3s ease;

            &:hover {
                color: $primary-color;
            }
        }

        &.active {
            .page-link {
                color: $primary-color;
            }
        }
    }

    .prev,
    .next {
        border: 1px solid $border-color;
        border-radius: 30px;
        padding: 5px 10px;
        transition: .3s ease;

        &:hover {
            border-color: $primary-color;

            .page-link {
                color: $primary-color;
            }
        }
    }

    .prev {
        margin-right: 30px;
    }

    .next {
        margin-left: 30px;
    }
}

/* 21.4.1 post slider */
.post-slider {
    position: relative;

    .prevArrow,
    .nextArrow {
        position: absolute;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        line-height: 50px;
        top: 50%;
        border: 0;
        font-size: 20px;
        background: transparent;
        color: $primary-color;
        z-index: 9;
        background: rgba($color: $white, $alpha: 0.8);
        transition: .3s ease;

        &:focus {
            outline: 0;
        }

        &:hover {
            background: rgba($color: $primary-color, $alpha: .25);
        }
    }

    .prevArrow {
        left: 40px;
    }

    .nextArrow {
        right: 40px;
    }

    .slick-disabled {
        color: #e5e5e5;
    }
}

/* 21.4.4 sidebar */
.search-wrapper {
    input {
        background: $bg-gray;
        border-radius: 30px;
        font-weight: 400;
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
        font-size: 13px;
    }

    position: relative;

    &::before {
        content: "\e610";
        font-family: $icon-font;
        position: absolute;
        top: 50%;
        right: 20px;
        color: $text-color;
        transform: translateY(-50%);
    }
}

ul.tag-list {
    li {
        a {
            color: $text-color;
            padding: 10px 15px;
            display: block;
            border: 1px solid $border-color;
            border-radius: 30px;
            font-size: 13px;
            transition: .3s ease;

            &:hover {
                background: $primary-color;
                border-color: $primary-color;
                color: $white;
            }
        }

        margin-bottom: 7px;
    }
}

.newsletter {
    .form-control {
        font-weight: 400;
        padding: 0 20px;
        height: 40px;
        line-height: 40px;
        border-radius: 30px;
        font-size: 13px;
    }
}

/* 21.5 blog single start */

/* share icon */
.share-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    display: inline-block;

    i {
        color: $white;
    }
}

/* 21.5.7 blog sidebar */
.sidebar-box-shadow {
    box-shadow: -3px 0px 3px 0px rgba(0, 0, 0, 0.06);
}

/* blog single end */